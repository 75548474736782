.read-controls-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: $primary-color;
    .read-controls {
        /* position: absolute; */
        //background: rgba(77,77,77,0.5);
        border-radius: 10px;
        margin-top: 15px;
        width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .control {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
        }
    }
    .post-title {
        h2 {
            padding: 15px;
            max-width: 600px;
            margin: 0 auto;
        }
        a,
        a:visited {
            color: white;
            font-size: 0.9em;
            font-family: 'Montserrat';
        }
        .subtitle {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            padding: 0 30px 30px 0;
            .subreddit {
                font-family: 'Lato';
                font-size: 16px;
                color: white;
                font-style: italic;
                padding: 0 30px;
            }
        }
    }
}

.read-content {
    .read-content-inner {
        padding: 2em;
        .blocked-content {
            font-family: anironregular;
            display: flex;
            flex-direction: row;
            align-items: center;
            img {
                max-width: 40%;
            }
            p {
                font-family: 'Montserrat';
            }
        }
    }
}

.read-controls-footer {
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-content: center;
    padding-right: 2em;
    .control {
        a,
        button {
            margin-left: 30px;
            font-family: Montserrat;
            font-weight: 500;
        }
    }
}

.vid-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
