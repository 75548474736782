.sign-up-container {
    background: $primary-color;
    color: white;

    .sign-up-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .logo {
            width: 120px;
        }

        .site-name {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 3em;
            font-family: anironregular;
            .sign-up-message,
            .caption {
                margin: 20px;
                font-size: 18px;
                //font-family: Zilla Slab;
                font-family: Montserrat;
                a {
                    color: white;
                    text-decoration: underline;
                }
            }
        }
    }
}
