@import './styles/vars';
// @import '../node_modules/spectre.css/dist/spectre.min.css';
@import '../node_modules/spectre.css/dist/spectre-icons.min.css';
@import '../node_modules/spectre.css/src/spectre';
@import url('https://fonts.googleapis.com/css?family=Zilla+Slab');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,Montserrat:600i,Lato:100i');

@import './styles/layout';
@import './styles/off-canvas';
@import './styles/sign-up-header';
@import './styles/about';
@import './styles/mobile';
@import './styles/modal';
