@import './read-controls';

#root {
    height: 100%;
}

.App {
    height: 100%;
    .main-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .no-content-wrapper {
        margin-top: 8em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        font-family: 'Zilla Slab', 'serif';
        font-size: 22px;
        text-align: center;
    }
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on January 10, 2019 */
@font-face {
    font-family: 'anironregular';
    src: url('fonts/anirm___-webfont.woff2') format('woff2'), url('fonts/anirm___-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'anironbold';
    src: url('fonts/anirb___-webfont.woff2') format('woff2'), url('fonts/anirb___-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #5d5d5d;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container {
    width: 100%;
    height: 100%;
    padding: 0;
    .navigation {
        margin: 0;
    }
}

.site-wrap {
    /* Critical position and size styles */
    min-height: 100%;
    min-width: 100%;
    background-color: white; /* Needs a background or else the nav will show through */
    position: relative;
    top: 0;
    bottom: 100%;
    left: 0;
    z-index: 1;

    /* non-critical apperance styles */
    padding: 0 4em 4em;
    background: #f3f6f8;

    // Header
    .header {
        margin-bottom: 30px;
        justify-content: space-between;
        flex-direction: row-reverse;
        .banner-img {
            display: flex;
            .site-name {
                display: flex;
                flex-direction: column;
                justify-content: center;

                h1 {
                    margin: 0;
                    font-family: 'Zilla Slab', serif;
                    font-size: 60px;
                    color: #51565c;
                }

                .caption {
                    margin-top: -3px;
                }
            }
        }
        .reddzit-nav {
            display: flex;
            flex-direction: row;
            align-items: center;
            a {
                margin: 0 15px;
                padding: 0 30px;
                &:hover {
                    color: $dark-primary;
                }
            }
        }
    }

    // Columns
    .columns {
        margin-bottom: 40px;

        .column {
            a,
            h5 {
                color: $primary-color;
                cursor: pointer;
                &:hover {
                    color: $dark-primary;
                    text-decoration: none;
                }
            }
            .button-wrapper {
                display: inline-block;
                padding: 0.5em;
                svg:hover {
                    color: white;
                }
            }
        }
    }

    .pagination-wrapper {
        padding: 4em;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}
