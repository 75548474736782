.header {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-evenly;
    max-height: 100px;
}

.header img {
    width: 100px;
    height: 100px;
}