// Screen sizes
$screen-xs-min: 576px;
$screen-sm-min: 768px;
$screen-md-min: 922px;
$screen-lg-min: 1200px;

@media (max-width: $screen-md-min) {
    html,
    body {
        background-color: $darkest-purple;
        background-size: cover;
        background-position: top center;
    }
    .container {
        padding: 0;
        .navigation {
            position: relative;
            margin: 0;
            overflow-y: hidden;
            height: auto;
            label.nav-trigger-label {
                position: relative;
                margin: 0 7% 5% 0;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                h3 {
                    margin: 0 2% -1% 0;
                    position: relative;
                    margin-right: 20px;
                    margin-top: 2px;
                }
            }
            &.open {
                display: block;
            }
        }
    }
    .site-wrap {
        display: block;
        padding: 16px;
        background-color: $site-wrap;
        font-family: 'Montserrat';
        .header {
            display: flex;
            flex-direction: column;
            .banner-img .site-name {
                .caption {
                    color: white;
                }
                h1 {
                    font-size: 40px;
                    color: white;
                }
            }
            .reddzit-nav {
                align-items: flex-end;
                justify-content: space-between;
                display: flex;
                flex-direction: row;
                text-align: right;

                a {
                    padding: 0;
                    // padding-top: 20px;
                }
            }
        }
        a,
        a:visited {
            color: #faf9ff;
        }
        .content {
            .filter-wrapper {
                padding-bottom: 15px;
                width: 100%;
                display: flex;
                justify-content: flex-end;

                button {
                    color: #faf9ff;
                    border: 1px solid;
                }
            }
        }
        .columns {
            .column {
                a,
                h5 {
                    color: #faf9ff;
                    &:hover {
                        color: #faf9ff;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .nav-trigger:checked + label {
        left: auto;
        right: 5%;
    }

    .nav-trigger:checked ~ .site-wrap {
        left: 100%;
        display: none;
    }
    .nav-trigger:checked ~ .copy-wrapper {
        left: auto;
        right: 5%;
        position: fixed;
        top: 70px;
        z-index: 2;
        height: 30px;
        width: 30px;
    }
    .read-content {
        width: 100%;
        margin: 0;
        .read-controls-wrapper {
            color: white;
            .post-title {
                h2 {
                    font-size: 1.2rem;
                }
            }
        }
        .read-content-inner {
            padding-left: 20px;
            &.media {
                padding: 0;
                margin: 25px 0 40px 0;
            }
        }
    }
}
