$primary-color: #b6aaf1;
$dark-primary: #7563ca;
$darkMode: #322a5a;
$darkModeFont: #dcdce0;

$funkyPurple: #9f72d6;

$dark-green: #212724;
$medium-green: #64a785;

$purple-2: #7e87ef;
$light-purple3: #ab8eca;
$darkest-purple: #262129;
$solid-pink: #bf659b;

$site-wrap: $primary-color;
